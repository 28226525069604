import React from "react";
import { Link } from "gatsby";

import { Breadcrumb } from "../components";

const Announcements = props => {
  const { data } = props;

  const breadcrumbData = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "Announcement",
    },
  ];

  return (
    <div className="announcements">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mt-5">
              <Breadcrumb data={breadcrumbData} />
            </div>
            <h2 className="announcements__title">Announcements</h2>
            <div className="announcements__content">
              {data?.map(item => {
                return (
                  <Link
                    className="announcements__card mb-4"
                    key={item.uuid}
                    to={"/announcement-detail/" + item.uuid}
                  >
                    <div className="announcements__card-detail">
                      <h4 className="announcements__card-title">
                        {item.title}
                      </h4>
                      <p className="announcements__card-desc">
                        {item.description}
                      </p>
                    </div>
                    <div className="announcements__card-img">
                      <img src={item.image_url} alt={item.title} />
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcements;
