import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import Announcements from "../container/announcements";
import { BACKEND_BASE_URL } from "../constant/constants";

const AnnouncementsPage = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Announcements" />
      <Announcements data={serverData.announcements} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const announcements = await fetch(BACKEND_BASE_URL + "announcements");

    if (!announcements.ok) {
      throw new Error(`Response failed`);
    }

    return {
      props: {
        announcements: await announcements.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default AnnouncementsPage;
